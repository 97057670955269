import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash-es';
import { ThunkDispatchType, actions, RootState, Grant, ZoomSettingsUpdate } from '../../../store';
import ZoomSignIn, { ZoomAccountInfo, ZoomExecData } from './ZoomSignIn';
import { cabCaptureMessage } from '../../../utils/logging';


const isSafeZoomUrl = (url: string) => {
  try {
    const parsed = new URL(url);
    const hostname = parsed.hostname.toLowerCase();

    return (
      hostname === 'zoom.us' ||
      hostname.endsWith('.zoom.us')
    );
  } catch {
    cabCaptureMessage(`Unsafe Zoom URL detected and blocked: ${url}`);
    return false;
  }
};

const ZoomSignInContainer = ({ zoomRedirectURI, grant, isOnboarding, useVerticalLayout }: {
  zoomRedirectURI?: string;
  grant?: Grant;
  onOAuthGranted: (providerId: number) => void;
  isOnboarding?: boolean;
  useVerticalLayout?: boolean;
}) => {

  const leaders = useSelector((state: RootState) => state.leaders);
  const zoomSettings = useSelector((state: RootState) => state.schedule.zoomSettings);
  const user = useSelector((state: RootState) => state.auth.user);


  const dispatch = useDispatch<ThunkDispatchType>();

  const [hasBasicAccess, setHasBasicAccess] = useState(true);
  // const [hasSharedAccess, setHasSharedAccess] = useState(true);
  const [zoomAccountsInfo, setZoomAccountsInfo] = useState<{ [oauthTokenId: number]: ZoomAccountInfo }>({});

  const uniqueZoomAccounts = useMemo(() => uniqBy(
    Object.values(zoomSettings)
      .filter(setting => setting.leader && leaders.leaders.find(l => l.id === setting.leader))
      .filter(s => s.oauth_token?.id)
      .map(s => s.oauth_token),
    token => token?.id
  ), [leaders.leaders, zoomSettings]);

  const asyncUseEffectFunc = useCallback(async () => {
    const infoPromises = uniqueZoomAccounts.map(async (token) => {
      const oauthTokenId = token?.id || 0;
      const username = token?.username || '';

      const res = await dispatch(actions.auth.getZoomSchedulers(oauthTokenId));

      // only do this for current users grant
      if (grant?.token_id === oauthTokenId) {
        // setHasSharedAccess(res.has_shared_permissions);
        setHasBasicAccess(res.has_basic_permissions);
      }

      let users: { id: string; email: string }[] = Object.values(res.zoom_user_schedulers)[0]?.schedulers || [];
      users = uniqBy([...users, { id: '-1', email: username }], 'id');

      const zs = {
        users,
        // hasSharedAccess: res.has_shared_permissions,
        hasBasicAccess: res.has_basic_permissions,
        username,
      };

      return { [oauthTokenId]: zs };
    });

    const info = (await Promise.all(infoPromises))
      .reduce((a, b) => ({ ...a, ...b }), {} as { [oauthId: number]: ZoomAccountInfo });

    setZoomAccountsInfo(info);
  }, [dispatch, grant?.token_id, uniqueZoomAccounts]);

  useEffect(() => {
    asyncUseEffectFunc();
  }, [asyncUseEffectFunc]);

  const handleSubmitZoomExecs = async (settings: { [leaderId: number]: ZoomExecData }) => {
    // Don't try to update anything for shared execs w/out admin access
    const settingsRes = Object.values(settings).filter(setting => {
      const leader = leaders.leaders.find(l => l.id === setting.leader);
      return !setting.is_shared || leader?.permissions.is_admin;
    }).map(setting => {
      const id = setting.id;
      const stg = { ...setting, oauth_token_id: setting.oauth_token?.id };
      if (id) {
        return dispatch(actions.schedule.updateZoomSettings({ id, ...stg }));
      } else {
        return dispatch(actions.schedule.createZoomSettings(stg));
      }
    });
    const newSettings = await Promise.all(settingsRes);

    const errors: { [leaderId: string]: string } = {};
    Object.keys(settings).forEach((stg: string, i) => {
      const res = newSettings[i];
      if (res && res.status === 400 && res?.data.detail.error?.personal_meeting_link) { 
        errors[stg] = res?.data.detail.error.personal_meeting_link.join('.\n');
      }
    });

    dispatch(actions.globalMessage.sendMessage({
      timeout: 2000,
      message: 'Settings have been updated',
      autoDismiss: true,
      header: '',
      position: { horizontal: 'center', vertical: 'bottom' },
      active: true,
      severity: "info",
    }));
  };

  const handleGrantOfflineClick = async () => {
    const authUrl = await dispatch(actions.auth.getZoomLoginUrl(zoomRedirectURI));
    if (!isSafeZoomUrl(authUrl)) {
      console.error('Unsafe Zoom URL detected:', authUrl);
      return;
    }
    window.location.href = authUrl;
  };

  const handleGetZoomContacts = (curGrant: Grant, searchStr: string) => (
    dispatch(actions.auth.getZoomContacts(curGrant, searchStr))
  );

  const handleUpdateZoomSettings = (settings: ZoomSettingsUpdate) => (
    dispatch(actions.schedule.updateZoomSettings(settings))
  );

  return <ZoomSignIn
    grant={grant}
    leaders={leaders}
    zoomAccountsInfo={zoomAccountsInfo}
    zoomSettings={zoomSettings}
    getZoomContacts={handleGetZoomContacts}
    updateZoomSettings={handleUpdateZoomSettings}
    onSubmitZoomExecs={handleSubmitZoomExecs}
    onGrantOfflineClick={handleGrantOfflineClick}
    hasBasicAccess={hasBasicAccess}
    // hasSharedAccess={hasSharedAccess}
    isOnboarding={isOnboarding}
    useVerticalLayout={useVerticalLayout}
    userLeader={user?.profile.user_leader || -1}
  />;
};

export default ZoomSignInContainer;
